body.modal-open #root {
    filter: blur(5px) grayscale(0%);
    -webkit-filter: blur(5px) grayscale(0%);
}

.download_modal {
    color: black;

    & .modal-content {
        border-radius: 4px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

        & .modal-header {
            padding-bottom: 0px;
            border: none !important;
            background-color: var(--green);
            align-items: flex-start;
        }

        & h2 {
            color: black;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            padding: none;
            border: none;
        }

        & .btn-primary {
            font-family: inherit;
            background: green;
        }
    }
}

.tinted {
    background: rgba(0, 0, 0, 0.2) !important;
}

#boom,
#download {
    text-align: center;
}

.intro_text_panel_old {
    padding: 20px;
    background: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(8px);
}

.intro_text_panel {
    font-size: 18px;
}

.into_pullquote {
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    font-style: italic;

    &._desktop {
        padding: 0px 100px;
    }
}

.video_title {
    display: flex;
    align-items: center;
}

.partner_logos {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 0px 20px;

    .partner_logo {
        height: 85px;
    }
}
