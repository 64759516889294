.intro_para {
    margin-top: -90px;
}

.testimonial_image {
    margin-bottom: 20px;
}

.attributation {
    color: var(--green);
}

.quote {
    position: relative;
    font-style: italic;
    font-size: 24px;
    line-height: 1.1;
    margin-bottom: 8px;
    color: var(--green);

    & p {
        padding: 20px;
    }

    &::before {
        content: '';
        position: absolute;
        left: -30px;
        top: -10px;
        font-size: 4rem;
    }
    &::after {
        content: '';
        position: absolute;
        right: -0px;
        bottom: 20px;
        font-size: 4rem;
    }
}
.quote_black {
    color: black;
}
.quote_white {
    color: rgb(255, 255, 255);
}
.quote_green {
    color: var(--green);
}
.inline_quote {
    padding: 16px 20px;
    border-radius: 0px;
    text-align: center;
    font-weight: 800;
}

.quote_wrapper {
    padding: 20px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
    justify-content: center;
    flex-direction: column;
    height: 400px;
    padding: 0px 30px;
}

.carousel_wrapper {
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
}

.testimonial_carousel {
    min-height: 460px;
    display: flex;

    & .carousel-indicators {
        align-items: flex-end !important;
    }

    & .carousel-control-next,
    .carousel-control-prev {
        align-items: flex-end !important;
    }
}

.form_intro {
    margin-top: 20px;
    font-style: italic;
    text-transform: uppercase;
    font-size: 18px;
}

.intro_overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 90;
}

.intro_section {
    transition: 1s;
    -webkit-animation: fade 4s infinite;
    -moz-animation: fade 4s infinite;
    -o-animation: fade 4s infinite;
    animation: fade 4s infinite;
    background-size: cover;
}
