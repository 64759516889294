.download_modal {
    & .modal-content {
        background: var(--lightgrey);
    }

    & .row {
        padding: 10px 20px;
    }

    & p {
        margin-bottom: 0px;
    }

    .form {
        padding: 0px;
        margin: 10px;
    }
}
