.pdf_holder {
    width: 100%;
    height: 100vh;
    margin-top: -70px;

    .spoof {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
    }
}
