@font-face {
    font-weight: 100 'Boing';
    font-family: 'Boing';
    src: url('./fonts/boing/Boing-Thin.woff2') format('woff2');
}

@font-face {
    font-weight: 300;
    font-family: 'Boing';
    src: url('./fonts/boing/Boing-Light.woff2') format('woff2');
}

@font-face {
    font-weight: 500;
    font-family: 'Boing';
    src: url('./fonts/boing/Boing-Regular.woff2') format('woff2');
}

@font-face {
    font-weight: 600;
    font-family: 'Boing';
    src: url('./fonts/boing/Boing-Medium.woff2') format('woff2');
}

@font-face {
    font-weight: 700;
    font-family: 'Boing';
    src: url('./fonts/boing/Boing-Semibold.woff2') format('woff2');
}

@font-face {
    font-weight: 900;
    font-family: 'Boing';
    src: url('./fonts/boing/Boing-Bold.woff2') format('woff2');
}

@font-face {
    font-weight: 200;
    font-family: 'Boing';
    font-style: italic;
    src: url('./fonts/boing/Boing-Light Italic.woff2') format('woff2');
}

@font-face {
    font-weight: 100;
    font-family: 'Boing';
    font-style: italic;
    src: url('./fonts/boing/Boing-Thin Italic.woff2') format('woff2');
}

@font-face {
    font-weight: 300;
    font-family: 'Boing';
    font-style: italic;
    src: url('./fonts/boing/Boing-Italic.woff2') format('woff2');
}

@font-face {
    font-weight: 500;
    font-family: 'Boing';
    font-style: italic;
    src: url('./fonts/boing/Boing-Medium Italic.woff2') format('woff2');
}

@font-face {
    font-weight: 700;
    font-family: 'Boing';
    font-style: italic;
    src: url('./fonts/boing/Boing-Semibold Italic.woff2') format('woff2');
}

@font-face {
    font-weight: 900;
    font-family: 'Boing';
    font-style: italic;
    src: url('./fonts/boing/Boing-Bold Italic.woff2') format('woff2');
}

:root {
    --lightgrey: #cfd2d4;
    --midgrey: #5d5d5d;
    --darkgrey: #373737;
    --green: #d2ff00;
    width: 100%;
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--darkgrey);
    font-size: 14px;
    line-height: 1.4;
    font-family: 'Boing', sans-serif;
    color: var(--lightgrey);
    font-weight: 500;
    padding: 0;
    width: 100%;
}

h1,
h2,
h3,
h4 {
    font-family: 'Boing', system-ui, -apple-system, sans-serif;
    font-weight: 500;
    color: var(--green);
    text-transform: uppercase;
    line-height: 1.1;
    font-style: italic;
    padding: 0;
    margin: 0em;
    margin-bottom: 0.3em;
}

h1 {
    font-size: 40px;
    font-weight: 900;
    color: rgb(194, 194, 194);
    margin-top: 20px !important;
}

h2 {
    font-size: 33px;
    color: rgb(194, 194, 194);
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
    color: rgb(231, 231, 231);
}

p {
    margin-bottom: 15px;

    & strong {
        color: white;
    }
}
.content {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0;
    margin-top: 70px;
}

.container-fluid,
.container {
    width: 100%;
    padding: 0;
    margin: 0;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.col {
    width: 100%;
}

.section {
    background-position: center top !important;
    background-repeat: no-repeat !important;
}

.btn {
    border: 0px;
    border-radius: 3px;
    padding: 8px 20px;
    font-weight: 500;
    font-size: 13px;
}

.hero_section {
    display: flex;
    flex-direction: column;
    min-height: 520px;
    align-items: flex-start;
    justify-content: center;
}

.greenBg {
    background: var(--green);
    color: var(--darkgrey) !important;
    & h1,
    h2,
    h3,
    h4 {
        color: var(--darkgrey) !important;
    }
}

.midgreyBg {
    background: var(--midgrey);
    color: var(--lightgrey) !important;
    & h1,
    h2,
    h3,
    h4 {
        color: white !important;
    }
}
.greyBg {
    background: var(--darkgrey);
    color: var(--lightgrey) !important;
    & h1,
    h2,
    h3,
    h4 {
        color: white !important;
    }
}

.lightgreyBg {
    background: var(--lightgrey);
    color: var(--darkgrey) !important;
    & h1,
    h2,
    h3,
    h4,
    strong {
        color: var(--darkgrey) !important;
    }
}

.row {
    padding: 20px;
}

.col,
.col-sm {
    border-radius: 3px;
}

a {
    text-decoration: none;
    font-weight: 900;
}

ul {
    margin-bottom: 2px;
}

li {
    margin-bottom: 15px;
}

.testimonial {
    margin-bottom: 20px;
}

._mobile.row {
    height: 460px;
}

._desktop.row {
    height: 0px !important;
    padding: 0px;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.carousel-control-prev,
.carousel-control-next {
    align-items: flex-end !important;
    width: 10%;
}
.carousel-indicators {
    margin-bottom: 4px;
}

strong,
b {
    font-weight: 900;
}

.stripes {
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.5) 25%,
        rgba(0, 0, 0, 0.7) 25%,
        rgba(0, 0, 0, 0.7) 50%,
        rgba(0, 0, 0, 0.5) 50%,
        rgba(0, 0, 0, 0.5) 75%,
        rgba(0, 0, 0, 0.7) 75%,
        rgba(0, 0, 0, 0.7) 100%
    );
    background-size: 10px 10px;
}

.form-label {
    margin-bottom: 0.1rem;
}

.cookie_consent {
    padding: 20px;

    & .btn {
        padding: 8px 20px !important;
        color: 'black' !important;
        font-size: '13px' !important;
        font-weight: 'bold' !important;
        background: var(--green) !important;
        border-radius: 4px !important;
    }
}
