.pdf_viewer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: -70px;

    .navbar {
        width: 100%;
        display: flex;
        position: fixed;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: rgb(37, 37, 37);
        margin-bottom: 40px;
        z-index: 90;
    }

    .pagination_count {
        display: flex;
        flex-direction: row;
        margin: 0px 6px;
    }

    .pagination_buttons {
        display: flex;
        flex-direction: row;

        .pagination_btn {
            margin: 0px 6px;
            padding: 8px 12px;
            background-color: rgba(255, 255, 255, 0.1);
            cursor: pointer;
            border-radius: 4px;
            transition: 0.2s;

            &:hover {
                background-color: rgba(255, 255, 255, 0.3);
            }
        }
    }

    .viewer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        margin-top: 20px;
    }

    .react-pdf__Page__canvas {
        margin: 0 auto;
        width: 70% !important;
        height: 30% !important;
    }

    @media only screen and (max-width: 600px) {
        .react-pdf__Page__canvas {
            margin: 0 auto;
            width: 90% !important;
            height: 30% !important;
        }

        .navbar {
            font-size: 13px !important;
        }
        .viewer {
            margin-top: 0px;
        }
    }

    .textLayer,
    .react-pdf__Page__annotations {
        display: none;
    }
}
