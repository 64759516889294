.footer {
    display: flex;

    & p {
        cursor: pointer;
        margin-bottom: 0px;

        &:hover {
            color: white;
        }
    }
}

.footer_col {
    text-align: center;
}

.sns_logo {
    outline: none;
    & svg {
        & path {
            fill: white;
        }
    }
}
