.legals_modal {
    & * {
        color: var(--darkgrey) !important;
    }

    & a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    & .modal-header {
        padding: 0px 30px;
        background: var(--lightgrey);
    }

    & .modal-body {
        padding: 30px;
    }

    [data-custom-class='link'],
    [data-custom-class='link'] * {
        color: var(--darkgrey) !important;
    }
}

table {
    --bs-table-border-color: #c6c6c6 !important;
}
