.form_wrapper {
    padding: 0px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 5px;
    margin: 30px 0px;
}

.field {
    padding: 10px 12px;
    font-size: 13px;
    font-weight: 500;
    color: #212529;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(8px) opacity(0.8);
    border-radius: 2px;
    border: 1px solid var(--midgrey);

    &:active {
        border: 1px solid green;
    }
}

.nopad {
    padding: 5px 0 !important;
}

a {
    color: var(--green);

    &:hover {
        color: white;
    }
}

.submit_btn {
    background-color: var(--green);
    border: 0px;
    border-radius: 3px;
    padding: 12px 20px;
}

.submit_progress {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-content: center;
    align-items: center;

    & svg {
        width: 24px;
        height: 24px;
        margin-left: 14px;
    }
}

.termscheckbox_line {
    display: flex;
    align-items: center;
}

.terms_link {
    font-weight: 900;
    cursor: pointer;

    &:hover {
        color: var(--midgrey);
    }
}
