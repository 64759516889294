.testimonial_carousel {
    display: flex;

    & .player-wrapper {
        margin-bottom: 10px;
        z-index: 900;
    }

    & .quote_atttibute {
        padding: 0px 0px;
        text-align: left;
        text-transform: uppercase;
    }
}
