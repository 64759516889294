.partner_logos {
    padding: 0px !important;
    margin: 0px !important;
}

.partners_holder {
    position: relative;
    width: 100%;
    max-width: 1200px;
    height: 280px;
    align-self: flex-end;

    .boom {
        position: absolute;
        width: 536px;
        height: 580px;
        opacity: 0.2;
        transform: translateX(-50%);
        left: 50%;
        top: -80px;
    }

    .boom_1 {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 536px;
        height: 580px;
    }

    .boom_2 {
        position: absolute;
        left: 80px;
        top: 114px;
        width: 320px;
        height: 246px;
    }

    .boom_3 {
        position: absolute;
        left: 205px;
        top: 167px;
        width: 123px;
        height: 98px;
    }

    .logo_left,
    .logo_right {
        position: absolute;
        top: 50px;
        width: 340px;
        max-width: 38%;
        height: 180px;
        border: 0px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo_left {
        left: 10%;
    }

    .logo_right {
        right: 10%;
    }

    .logo {
        width: 100%;
        object-fit: contain;
    }
}

@media screen and (max-width: 768px) {
    .partners_holder {
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo_left,
        .logo_right {
            top: 0px;
            position: relative;
            max-width: 90%;
            border: 0px solid black;
        }

        .logo_left {
            margin-top: -20px;
            left: auto;
        }

        .logo_right {
            margin-top: -60px;
            right: auto;
        }
    }
}
