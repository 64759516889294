.navbar {
    background: var(--darkgrey);
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-start;

    & a {
        color: white;
        text-decoration: none;
        font-weight: 500;
        padding: 12px;

        &:hover {
            color: var(--green);
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.navbar > .container {
    display: flex;
}

.headerlogo {
    height: 44px;
}

.menubars {
    display: flex !important;
    flex-basis: auto;
    flex-direction: row;
    justify-content: space-between;
}

.main_nav {
    display: flex;
}

.right_nav {
    display: none;
    margin-left: auto;
}
